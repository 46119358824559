import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import { Col, Row } from 'stillnovel/components/UI/Grid';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import Form from 'stillnovel/components/UI/Form';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import ImageInput from 'stillnovel/components/UI/Input/ImageInput';
import productsConfig from 'stillnovel/components/App/Project/config';
import FilterSelector from 'stillnovel/components/App/Project/Builders/ArtPrint/build-steps/shared/FilterSelector';

import FormHeader from '../../../FormHeader';
import ColorSelector from '../../shared/ColorSelector';
import StyleSelector from '../../shared/StyleSelector';
import StepNav from '../../../StepNav';
import validate from '../validate';

let BackgroundForm = ({
    handleSubmit,
    invalid,
    metadata,
    previousPage,
    project,
    submitting,
}) => {
    const config = productsConfig['birth-stat-snapshot'];
    const style = metadata?.style === 'Style 01' ? 'Style 01' : 'Style 02';
    const styleConfig = config.styles.find(
        styleConfig => style == styleConfig.styleHandle
    );

    return (
        <Fragment>
            <Form>
                <FormHeader heading="Background" />
                <StyleSelector
                    options={[
                        // { label: 'Style 01', value: 'Style 01' },
                        { label: 'Style 02', value: 'Style 02' },
                    ]}
                    style={metadata.style || 'Style 01'}
                />

                <Field
                    name="baseColor"
                    component={ColorSelector}
                    style={metadata.style || 'Style 02'}
                />

                <section>
                    <Field
                        name="imageMeta"
                        component={ImageInput}
                        targetWidth={styleConfig.photoTargetWidth}
                        targetHeight={styleConfig.photoTargetHeight}
                    />

                    <Field
                        name="filterSetting"
                        component={FilterSelector}
                        style={metadata.style || 'Style 02'}
                    />
                </section>

                <Row alignment="bottom">
                    <Col>
                        <label htmlFor="firstName" className={'form-title'}>
                            Name
                        </label>
                        <Field
                            id="firstName"
                            name="firstName"
                            type="text"
                            component={FieldInput}
                            placeholder="First"
                            autoComplete={`${project.id}-firstName`}
                            autoCorrect="off"
                        />
                    </Col>
                    <Col>
                        <Field
                            name="middleName"
                            type="text"
                            component={FieldInput}
                            placeholder="Middle"
                            autoComplete={`${project.id}-middleName`}
                            autoCorrect="off"
                        />
                    </Col>
                    <Col>
                        <Field
                            name="lastName"
                            type="text"
                            component={FieldInput}
                            placeholder="Last"
                            autoComplete={`${project.id}-lastName`}
                            autoCorrect="off"
                        />
                    </Col>
                </Row>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage, disabled: true },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

BackgroundForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    metadata: PropTypes.object.isRequired,
    previousPage: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
};

BackgroundForm.defaultProps = {
    invalid: false,
    submitting: false,
};

BackgroundForm = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(BackgroundForm);

export default BackgroundForm;
