import PropTypes from 'prop-types';
import React from 'react';
import CloseButton from 'stillnovel/components/UI/CloseButton';

import styles from './LocationCard.module.css';

const LocationCard = ({
    input = { value: { city: { long_name: '' }, state: { short_name: '' } } },
    onClear = () => {},
}) => {
    const city = input.value.city.long_name || '';
    const state = input.value.state.short_name
        ? `, ${input.value.state.short_name}`
        : '';

    return (
        <div className={styles.root}>
            <span className="body1">
                <span className={styles.label}>Selected:</span> {city}
                {state}
            </span>
            <CloseButton className={styles.closeButton} onClick={onClear} />
        </div>
    );
};

LocationCard.propTypes = {
    input: PropTypes.shape({
        value: PropTypes.shape({
            city: PropTypes.shape({
                long_name: PropTypes.string,
            }),
            state: PropTypes.shape({
                short_name: PropTypes.string,
            }),
        }),
    }),
    onClear: PropTypes.func,
};

export default LocationCard;
