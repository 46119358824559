import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import Loader from 'stillnovel/components/UI/Loader';
import Section from 'stillnovel/components/UI/Section';
import { useGraph } from 'stillnovel/hooks/useContentful';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Link from 'stillnovel/components/UI/Link';

import styles from './Summary.module.css';

const options = {
    renderMark: {
        [MARKS.BOLD]: text => <span className="body1-alt">{text}</span>,
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => (
            <p className="body1">{children}</p>
        ),
        [BLOCKS.HEADING_1]: (node, children) => (
            <h1 className="display">{children}</h1>
        ),
        [BLOCKS.HEADING_2]: (node, children) => (
            <h2 className="header">{children}</h2>
        ),
        [BLOCKS.HEADING_3]: (node, children) => (
            <h3 className="title">{children}</h3>
        ),
        [BLOCKS.HEADING_4]: (node, children) => (
            <h4 className="title-alt3">{children}</h4>
        ),
        [BLOCKS.HEADING_5]: (node, children) => (
            <h5 className="subhead">{children}</h5>
        ),
        [BLOCKS.HEADING_6]: (node, children) => (
            <h6 className="body1-alt">{children}</h6>
        ),
        [BLOCKS.UL_LIST]: (node, children) => (
            <ul className={styles.list}>{children}</ul>
        ),
        [BLOCKS.LIST_ITEM]: (node, children) => (
            <li className={styles.listItem}>{children}</li>
        ),
        [INLINES.HYPERLINK]: node => (
            <Link href={node.data.uri} className={styles.link}>
                {node.content[0].value}
            </Link>
        ),
    },
};

function Summary(props) {
    const { cta, isLoading, totalSub } = props;
    const preview = false;

    const cartCalloutContent = useGraph({
        query: `
            query {
                blockCartCalloutCollection(preview: ${preview}) {
                    items {
                        richText {
                            json
                        }
                    }
                }
            }
        `,
    });

    const richText =
        cartCalloutContent?.data?.blockCartCalloutCollection?.items[0]?.richText
            ?.json;

    return (
        <div className={styles.root}>
            <Section
                className={cx(styles['cart-row'], styles['cart-row-sub-total'])}
            >
                <div className={styles['cart-rows']}>
                    <ul>
                        <li
                            className={cx(
                                styles['cart-sub-row'],
                                styles['cart-sub-row-sub-total']
                            )}
                        >
                            <span className="body1-alt">Sub-total</span>
                            <span className="body1-alt">{totalSub}</span>
                        </li>
                        {richText && (
                            <li
                                className={`${styles['shipping-message']} body1 warning-text`}
                            >
                                {documentToReactComponents(richText, options)}
                            </li>
                        )}
                    </ul>
                    {isLoading && (
                        <div className={styles.loader}>
                            <Loader align="center" text="Calculating..." />
                        </div>
                    )}
                </div>
            </Section>
            {cta && (
                <Section
                    className={cx(
                        styles['cart-row'],
                        styles['cart-row-checkout']
                    )}
                >
                    {cta()}
                </Section>
            )}
        </div>
    );
}

Summary.propTypes = {
    cta: PropTypes.func,
    isLoading: PropTypes.bool,
    totalSub: PropTypes.string,
};

export default Summary;
