import PropTypes from 'prop-types';
import React from 'react';

import styles from './Header.module.css';

function Header({ headline, tag, children }) {
    const Tag = tag ?? 'h1';
    return (
        <header className={styles.root}>
            {headline && <Tag className="jumbo">{headline}</Tag>}
            {children}
        </header>
    );
}

Header.propTypes = {
    headline: PropTypes.string,
    tag: PropTypes.string,
    children: PropTypes.node,
};

export default Header;
