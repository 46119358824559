import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Col, Row } from 'stillnovel/components/UI/Grid';
import Form from 'stillnovel/components/UI/Form';
import NavArrows from 'stillnovel/components/UI/NavArrows';

import FormHeader from '../../../FormHeader';
import ColorSelector from '../../shared/ColorSelector';
import StepNav from '../../../StepNav';
import validate from '../validate';

const BackgroundForm = ({
    handleSubmit,
    invalid = false,
    metadata,
    previousPage,
    submitting = false,
}) => {
    return (
        <>
            <Form>
                <FormHeader heading="Stylize" />
                <Row>
                    <Col>
                        <Field
                            name="baseColor"
                            component={ColorSelector}
                            style={metadata.style || 'modern'}
                        />
                        <span className="caption">
                            *We apply a softening layer over all photos to allow
                            for the design and text to subtly pop over your
                            image.
                        </span>
                    </Col>
                </Row>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                        },
                    ]}
                />
            </StepNav>
        </>
    );
};

BackgroundForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    metadata: PropTypes.shape({
        style: PropTypes.string,
    }).isRequired,
    previousPage: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
};

export default reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(BackgroundForm);
