import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './Row.module.css';

const Row = ({ alignment = 'top', children, tag = 'div', style }) => {
    const TagName = tag;
    const classNames = cx(styles.row, {
        [styles.start]: alignment === 'start',
        [styles.center]: alignment === 'center',
        [styles.end]: alignment === 'end',
        [styles.top]: alignment === 'top',
        [styles.middle]: alignment === 'middle',
        [styles.bottom]: alignment === 'bottom',
    });
    return (
        <TagName className={classNames} style={style}>
            {children}
        </TagName>
    );
};

Row.propTypes = {
    alignment: PropTypes.string,
    children: PropTypes.node.isRequired,
    style: PropTypes.object,
    tag: PropTypes.string,
};

export default Row;
