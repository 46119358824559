import PropTypes from 'prop-types';
import React from 'react';
import Section, { Head } from 'stillnovel/components/UI/Section';

import CartItem from './CartItem';
import styles from './CartItems.module.css';

const CartItems = ({ cartItems }) => {
    return (
        <Section className={styles['cart-content']}>
            <Head
                cells={[
                    { label: 'Item', colSpan: 4 },
                    { label: 'Name', colSpan: 2 },
                    { label: 'Details', colSpan: 2 },
                ]}
            />
            <ul className={styles['cart-items']} role="list">
                {cartItems.map((item, i) => (
                    <CartItem key={i} {...item} />
                ))}
            </ul>
        </Section>
    );
};

CartItems.propTypes = {
    cartItems: PropTypes.array,
};

CartItems.defaultProps = {
    cartItems: [],
};

export default CartItems;
