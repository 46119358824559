import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Form from 'stillnovel/components/UI/Form';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import ImageInput from 'stillnovel/components/UI/Input/ImageInput';
import productsConfig from 'stillnovel/components/App/Project/config';

import FormHeader from '../../../FormHeader';
import StepNav from '../../../StepNav';
import validate from '../validate';

const BackgroundForm = ({
    handleSubmit,
    invalid = false,
    metadata,
    previousPage,
    submitting = false,
}) => {
    const config = productsConfig['birth-stat'];
    const style = metadata?.style === 'vintage' ? 'vintage' : 'modern';
    const styleConfig = config.styles.find(
        styleConfig => style === styleConfig.styleHandle
    );

    return (
        <>
            <Form>
                <FormHeader heading="Photo" />
                <Field
                    name="imageMeta"
                    component={ImageInput}
                    targetWidth={styleConfig?.photoTargetWidth}
                    targetHeight={styleConfig?.photoTargetHeight}
                />
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                        },
                    ]}
                />
            </StepNav>
        </>
    );
};

BackgroundForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    metadata: PropTypes.shape({
        style: PropTypes.string,
    }).isRequired,
    previousPage: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
};

export default reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(BackgroundForm);
