import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import { Container, Col, Row } from 'stillnovel/components/UI/Grid';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import Form from 'stillnovel/components/UI/Form';
import NavArrows from 'stillnovel/components/UI/NavArrows';

import TextPreview from 'stillnovel/components/UI/TextPreview';

import FormHeader from '../../../FormHeader';
import validate from '../validate';
import StepNav from '../../../StepNav';

let Stats = ({
    handleSubmit,
    invalid = false,
    previousPage,
    submitting = false,
    project,
    metadata,
}) => {
    return (
        <Fragment>
            <Form>
                <FormHeader heading="Additional Details" />
                <Container>
                    <Row>
                        <Col>
                            <span
                                htmlFor="favoriteFood"
                                className={'form-title'}
                            >
                                Favorite Food
                            </span>
                            <Field
                                name="favoriteFood"
                                component={FieldInput}
                                placeholder="Ex: “Pizza”"
                                maxLength="29"
                                autoComplete={`${project.id}-favoriteFood`}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <span
                                htmlFor="favoritePlace"
                                className={'form-title'}
                            >
                                Favorite Place
                            </span>
                            <Field
                                name="favoritePlace"
                                component={FieldInput}
                                placeholder="Ex: “our couch” or “Big Bear Lake”"
                                maxLength="29"
                                autoComplete={`${project.id}-favoritePlace`}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label
                                htmlFor="favoritePastime"
                                className={'form-title'}
                            >
                                Favorite Pastime
                            </label>
                            <Field
                                name="favoritePastime"
                                component={FieldInput}
                                placeholder="Ex: “mid-day movies” or “hiking”"
                                maxLength="29"
                                autoComplete={`${project.id}-favoritePastime`}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <label
                                style={{ lineHeight: '3.5' }}
                                className={'form-title'}
                                htmlFor="startedWith1"
                            >
                                It all started with{' '}
                                <span>
                                    <Field
                                        inline
                                        name="startedWith1"
                                        component={FieldInput}
                                        placeholder="Ex: “a little smile”"
                                        maxLength="29"
                                        autoComplete={`${project.id}-startedWith1`}
                                    />{' '}
                                    and{' '}
                                    <Field
                                        inline
                                        name="startedWith2"
                                        component={FieldInput}
                                        placeholder="Ex: “a phone call”"
                                        autoComplete={`${project.id}-startedWith`}
                                    />
                                </span>
                            </label>
                            <TextPreview
                                string={`It all started with <em>${metadata.startedWith1 ?? ''}</em> and <em>${metadata.startedWith2 ?? ''}</em>.`}
                            />
                        </Col>
                    </Row>
                </Container>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                            label: 'Review',
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

Stats.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    previousPage: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    project: PropTypes.object.isRequired,
    metadata: PropTypes.object.isRequired,
};

Stats = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(Stats);

export default Stats;
