import React from 'react';
import Field from 'redux-form/es/Field';
import { Col, Row } from 'stillnovel/components/UI/Grid';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import FieldSelect from 'stillnovel/components/UI/Input/FieldSelect';

const otherBreedGroup = [
    ['Fish', 'FISH'],
    ['Goat', 'GOAT'],
    ['Guinea Pig', 'GUINEA_PIG'],
    ['Horse', 'HORSE'],
    ['Hamster', 'HAMSTER'],
    ['Pig', 'PIG'],
    ['Reptile', 'REPTILE'],
    ['Turtle', 'TURTLE'],
    ['Tortoise', 'TORTOISE'],
    ['Bird', 'BIRD'],
    ['Bunny', 'BUNNY'],
    ['Other', 'OTHER'],
];

const Other = () => {
    return (
        <>
            <Row>
                <Col>
                    <label htmlFor="otherBreedGroup" className={'form-title'}>
                        Animal
                    </label>
                    <Field
                        name="otherBreedGroup"
                        component={FieldSelect}
                        stretch
                    >
                        <option value="" disabled>
                            --
                        </option>
                        {otherBreedGroup.map(([label, key]) => {
                            return (
                                <option key={key} value={key}>
                                    {label}
                                </option>
                            );
                        })}
                    </Field>
                </Col>
            </Row>
            <Row alignment="bottom">
                <Col>
                    <label htmlFor="breed" className={'form-title'}>
                        Breed
                    </label>
                    <Field
                        name="breed"
                        type="text"
                        component={FieldInput}
                        placeholder="EX: “Parakeet” or “Shetland Pony”"
                        autoComplete={`breed`}
                        autoCorrect="off"
                        maxLength={30}
                    />
                </Col>
            </Row>
        </>
    );
};

export default Other;
