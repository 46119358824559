import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import StepNav from 'stillnovel/components/App/Project/Builders/ArtPrint/StepNav';
import { Container, Col, Row } from 'stillnovel/components/UI/Grid';
import DateInput from 'stillnovel/components/UI/Input/DateInput';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import Form from 'stillnovel/components/UI/Form';
import FormHeader from 'stillnovel/components/App/Project/Builders/ArtPrint/FormHeader';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import PlacesAutocomplete from 'stillnovel/components/UI/PlacesAutocomplete';
import { FieldSelect } from 'stillnovel/components/UI/Input';

import TextPreview from 'stillnovel/components/UI/TextPreview';

import validate from '../validate';

let Details = ({
    handleSubmit,
    invalid = false,
    metadata,
    previousPage,
    project,
    submitting = false,
}) => {
    return (
        <Fragment>
            <Form>
                <FormHeader heading="Engagement Details" />
                <Container>
                    <Row>
                        <Col>
                            <label
                                htmlFor="ceremonyLocationData"
                                className={'form-title'}
                            >
                                Proposal City and State
                            </label>
                            <Field
                                id="ceremonyLocationData"
                                name="ceremonyLocationData"
                                component={PlacesAutocomplete}
                                placeholder="Ex: “Los Angeles, CA”"
                                autoComplete={`${project.id}-ceremonyLocation`}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label
                                htmlFor="theProposal"
                                className={'form-title'}
                            >
                                Proposal Location (The place)
                            </label>
                            <Field
                                id="theProposal"
                                name="theProposal"
                                component={FieldInput}
                                placeholder="Ex: “St”. Andrews Church"
                                maxLength="23"
                                autoComplete={`${project.id}-theProposal`}
                                autoCorrect="off"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label
                                htmlFor="experienceWord"
                                className={'form-title'}
                            >
                                The day was
                            </label>
                            <Field
                                id="experienceWord"
                                name="experienceWord"
                                component={FieldInput}
                                placeholder="Ex: perfect"
                                maxLength="23"
                                autoComplete={`${project.id}-experienceWord`}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label
                                htmlFor="weCelebratedWord"
                                className={'form-title'}
                            >
                                We celebrated
                            </label>
                            <span>
                                <Field
                                    inline
                                    id="weCelebratedPreposition"
                                    name="weCelebratedPreposition"
                                    component={FieldSelect}
                                >
                                    <option value="" disabled>
                                        --
                                    </option>
                                    <option value="with">with</option>
                                    <option value="at">at</option>
                                    <option value="in">in</option>
                                </Field>
                                <Field
                                    inline
                                    id="weCelebratedWord"
                                    name="weCelebratedWord"
                                    component={FieldInput}
                                    placeholder="Ex: champagne"
                                    maxLength="23"
                                    autoComplete={`${project.id}-weCelebratedWord`}
                                />
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label
                                className={'form-title'}
                                htmlFor={'engagementDate'}
                            >
                                Engagement Date
                            </label>
                            <Field
                                name="engagementDate"
                                type="select"
                                component={DateInput}
                                showTime={false}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label
                                htmlFor="engagementTimeOfDay"
                                className={'form-title'}
                            >
                                The time of day
                            </label>
                            <Field
                                id="engagementTimeOfDay"
                                name="engagementTimeOfDay"
                                component={FieldSelect}
                                stretch
                            >
                                <option value="" disabled>
                                    --
                                </option>
                                <option value="morning">Morning</option>
                                <option value="afternoon">Afternoon</option>
                                <option value="evening">Evening</option>
                                <option value="night">Night</option>
                            </Field>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <span className={'form-title'}>
                                We will never forget{' '}
                                <span>
                                    <Field
                                        inline
                                        stretch
                                        name="wellNeverForget1"
                                        component={FieldInput}
                                        placeholder="Ex: “how nervous he looked”"
                                        autoComplete={`${project.id}-wellNeverForget1`}
                                        autoCapitalize="off"
                                        maxLength={45}
                                        style={{ minWidth: '230px' }}
                                    />{' '}
                                    and{' '}
                                    <Field
                                        inline
                                        stretch
                                        name="wellNeverForget2"
                                        component={FieldInput}
                                        placeholder="Ex: “the look on her face”"
                                        autoComplete={`${project.id}-wellNeverForget2`}
                                        autoCapitalize="off"
                                        maxLength={45}
                                        style={{ minWidth: '198px' }}
                                    />
                                </span>
                            </span>
                            <TextPreview
                                string={`We will never forget <em>${metadata.wellNeverForget1 ?? ''}</em> and <em>${metadata.wellNeverForget2 ?? ''}</em>.`}
                            />
                        </Col>
                    </Row>
                </Container>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

Details.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    metadata: PropTypes.object,
    previousPage: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
};

Details = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(Details);

export default Details;
