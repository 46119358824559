import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import { Col, Row } from 'stillnovel/components/UI/Grid';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import FieldSelect from 'stillnovel/components/UI/Input/FieldSelect';
import FieldRadio from 'stillnovel/components/UI/Input/FieldRadio';
import DateInput from 'stillnovel/components/UI/Input/DateInput';
import Form from 'stillnovel/components/UI/Form';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import ImageInput from 'stillnovel/components/UI/Input/ImageInput';
import productsConfig from 'stillnovel/components/App/Project/config';

import StyleSelector from '../../shared/StyleSelector';
import FormHeader from '../../../FormHeader';
import ColorSelector from '../../shared/ColorSelector';
import StepNav from '../../../StepNav';
import validate from '../validate';

let BackgroundForm = ({
    handleSubmit,
    invalid,
    metadata,
    previousPage,
    project,
    submitting,
}) => {
    const config = productsConfig.pet;
    const style = metadata?.style === 'vintage' ? 'vintage' : 'modern';
    const styleConfig = config.styles.find(
        styleConfig => style == styleConfig.styleHandle
    );

    return (
        <Fragment>
            <Form>
                <FormHeader heading="Background" />
                <StyleSelector />
                <Row alignment="bottom">
                    <Col>
                        <label htmlFor="pet" className={'form-title'}>
                            Pet
                        </label>
                    </Col>
                    <Col colSpan={1}>
                        <Field
                            name="pet"
                            component={FieldRadio}
                            label="Dog"
                            id={'DOG'}
                        />
                    </Col>
                    <Col colSpan={1}>
                        <Field
                            name="pet"
                            component={FieldRadio}
                            label="Cat"
                            id={'CAT'}
                        />
                    </Col>
                    <Col colSpan={1}>
                        <Field
                            name="pet"
                            component={FieldRadio}
                            label="Other"
                            id={'OTHER'}
                        />
                    </Col>
                </Row>
                <Row alignment="bottom">
                    <Col>
                        <label htmlFor="firstName" className={'form-title'}>
                            Name
                        </label>
                        <Field
                            id="firstName"
                            name="firstName"
                            type="text"
                            component={FieldInput}
                            placeholder="First"
                            autoComplete={`${project.id}-firstName`}
                            autoCorrect="off"
                        />
                    </Col>
                    <Col>
                        <Field
                            name="middleName"
                            type="text"
                            component={FieldInput}
                            placeholder="Middle (optional)"
                            autoComplete={`${project.id}-middleName`}
                            autoCorrect="off"
                        />
                    </Col>
                    <Col>
                        <Field
                            name="lastName"
                            type="text"
                            component={FieldInput}
                            placeholder="Last (optional)"
                            autoComplete={`${project.id}-lastName`}
                            autoCorrect="off"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col colSpan={2}>
                        <label htmlFor="nickname" className={'form-title'}>
                            Nickname
                        </label>
                        <Field
                            id="nickname"
                            name="nickname"
                            type="text"
                            component={FieldInput}
                            placeholder="Ex: “Buddy Boy”"
                            autoComplete={`${project.id}-nickname`}
                            autoCorrect="off"
                        />
                    </Col>
                    <Col colSpan={2}>
                        <label htmlFor="gender" className={'form-title'}>
                            Gender
                        </label>
                        <Field name="gender" component={FieldSelect} stretch>
                            <option disabled value="">
                                --
                            </option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Omit">Omit</option>
                        </Field>
                    </Col>
                </Row>
                <Row alignment="bottom">
                    <Col colSpan={2}>
                        <label htmlFor="dateType" className={'form-title'}>
                            Birthday or adoption day
                        </label>
                        <Field name="dateType" component={FieldSelect} stretch>
                            <option value="" disabled>
                                --
                            </option>
                            <option value="BIRTH">Birth</option>
                            <option value="ADOPTION">Adoption</option>
                        </Field>
                    </Col>
                    <Col colSpan={2}>
                        <Field
                            name="date"
                            type="select"
                            component={DateInput}
                            showTime={false}
                        />
                    </Col>
                </Row>
                <Field
                    name="imageMeta"
                    component={ImageInput}
                    targetWidth={styleConfig.photoTargetWidth}
                    targetHeight={styleConfig.photoTargetHeight}
                />
                <Row>
                    <Col>
                        <Field
                            name="baseColor"
                            style={metadata.style || 'modern'}
                            component={ColorSelector}
                        />
                        <span className={'caption'}>
                            *We apply a softening layer over all photos to allow
                            for the design and text to subtly pop over your
                            image.
                        </span>
                    </Col>
                </Row>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage, disabled: true },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

BackgroundForm.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    metadata: PropTypes.object.isRequired,
    previousPage: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
};

BackgroundForm.defaultProps = {
    invalid: false,
    submitting: false,
};

BackgroundForm = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(BackgroundForm);

export default BackgroundForm;
