import React, { forwardRef, useState, memo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useKey } from 'react-use';
import CloseButton from 'stillnovel/components/UI/CloseButton';
import { ImgOptimized } from 'stillnovel/components/ContentfulImg';

import { RichText } from '@shopify/hydrogen-react';

import styles from './FrameDetail.module.css';

const FrameDetail = forwardRef(
    ({ description, hide, images = [], title }, ref) => {
        const [transitionState, setTransitionState] = useState(null);

        const handleExit = () => {
            setTransitionState('exiting');
            hide();
        };

        // Close Frame Detail using esc button
        useKey('Escape', handleExit);

        return (
            <div
                ref={ref}
                className={cx(styles.detail, {
                    [styles['detail--exiting']]: transitionState === 'exiting',
                })}
                onClick={handleExit}
            >
                <CloseButton
                    className={styles['detail-close']}
                    onClick={handleExit}
                />
                <header className={styles['detail-header']}>
                    <h2 className="jumbo">{title}</h2>
                </header>
                <section className={styles['detail-content']}>
                    <div className={styles['detail-images']}>
                        {images?.map(({ image }, i) => (
                            <figure
                                key={i}
                                className={styles['detail-figure']}
                                style={{
                                    aspectRatio: image?.width / image?.height,
                                }}
                            >
                                <ImgOptimized
                                    src={image?.url}
                                    alt={title}
                                    priority={i === 0}
                                    width={image?.width}
                                    height={image?.height}
                                    className={styles['detail-image']}
                                    style={{
                                        '--animation-order': Math.random(),
                                    }}
                                    onLoad={e =>
                                        e.target.classList.add(styles.loaded)
                                    }
                                />
                            </figure>
                        ))}
                    </div>
                    <aside className={styles['detail-description']}>
                        <RichText
                            data={description}
                            components={{
                                text({ node }) {
                                    if (node.bold === true) {
                                        return (
                                            <b className="body1">
                                                {node.value}
                                            </b>
                                        );
                                    }
                                    return node.value;
                                },
                                paragraph({ node }) {
                                    return (
                                        <p className="body1">{node.children}</p>
                                    );
                                },
                            }}
                        />
                    </aside>
                </section>
            </div>
        );
    }
);

FrameDetail.propTypes = {
    description: PropTypes.object.isRequired,
    hide: PropTypes.func.isRequired,
    images: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
};

FrameDetail.displayName = 'FrameDetail';

export default memo(FrameDetail);
