import PropTypes from 'prop-types';
import React, { memo, useEffect, useCallback } from 'react';
import cx from 'classnames';
import getConfig from 'core/config';
import { useIntersection } from 'react-use';
import AuthTrigger from 'stillnovel/components/App/Auth/Trigger';
import Button from 'stillnovel/components/UI/Button';
import Logo from 'stillnovel/components/UI/Logo';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from 'stillnovel/store/auth/actions';

import { scrollToTop } from 'stillnovel/utils';

import styles from './Footer.module.css';

const currentYear = new Date().getFullYear();

function Footer({ footerInView }) {
    const intersectionRef = React.useRef(null);
    const intersection = useIntersection(intersectionRef, {
        root: null,
        rootMargin: '0px',
        threshold: 0,
    });

    const userRole = useSelector(state => state.auth?.user?.role);
    const dispatch = useDispatch();

    const handleLogout = useCallback(async () => {
        dispatch(logout());
        scrollToTop();
    }, [dispatch]);

    useEffect(() => {
        footerInView(!!intersection?.intersectionRatio > 0);
    }, [footerInView, intersection?.intersectionRatio]);

    return (
        <footer ref={intersectionRef} className={cx(styles.root)}>
            <div className={styles.inner}>
                <section className={styles.logo}>
                    <Button
                        className="body1-alt"
                        href={`${getConfig('marcomBaseUrl')}/`}
                    >
                        <Logo />
                    </Button>
                </section>
                <section className={styles.primary}>
                    <nav>
                        <Button
                            className="body1-alt"
                            href={`${getConfig('marcomBaseUrl')}/faq`}
                        >
                            FAQ
                        </Button>
                        <Button
                            className="body1-alt"
                            onClick={() => window.Reamaze.popup()}
                        >
                            Support
                        </Button>
                        {userRole && userRole !== 'guest' ? (
                            <Button
                                className="body1-alt"
                                onClick={() => handleLogout()}
                            >
                                Logout
                            </Button>
                        ) : (
                            <AuthTrigger>
                                <Button className="body1-alt">Login</Button>
                            </AuthTrigger>
                        )}
                    </nav>
                </section>
                <section className={styles.secondary}>
                    <Button
                        className="caption"
                        href={`${getConfig('marcomBaseUrl')}/terms`}
                    >
                        Terms & Conditions
                    </Button>
                    <span className="caption">
                        &copy; 2016–
                        {currentYear} Still Novel. All Rights Reserved.
                    </span>
                    <Button
                        className="caption"
                        href={`${getConfig('marcomBaseUrl')}/privacy`}
                    >
                        Privacy Policy
                    </Button>
                </section>
            </div>
        </footer>
    );
}

Footer.propTypes = {
    footerInView: PropTypes.func,
};

export default memo(Footer);
