import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import StepNav from 'stillnovel/components/App/Project/Builders/ArtPrint/StepNav';
import { Container, Col, Row } from 'stillnovel/components/UI/Grid';
import DateInput from 'stillnovel/components/UI/Input/DateInput';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import Form from 'stillnovel/components/UI/Form';
import FormHeader from 'stillnovel/components/App/Project/Builders/ArtPrint/FormHeader';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import PlacesAutocomplete from 'stillnovel/components/UI/PlacesAutocomplete';
import FieldSelect from 'stillnovel/components/UI/Input/FieldSelect';

import validate from '../validate';

const weatherOptions = [
    'Perfect',
    'Freezing',
    'Cold',
    'Chilly',
    'Wet',
    'Warm',
    'Hot',
    'Humid',
    'Unpredictable',
];

let Details = ({
    handleSubmit,
    invalid,
    previousPage,
    project,
    submitting,
    metadata: { numberOfTravelers = 1, style } = {},
}) => {
    const isVintageStyle = style === 'vintage';
    const pronoun = numberOfTravelers > 1 ? 'We' : 'I';
    return (
        <Fragment>
            <Form>
                <FormHeader heading="Details" />
                <Container>
                    <Row>
                        <Col>
                            <label
                                htmlFor="numberOfTravelers"
                                className={'title'}
                            >
                                Number of Travelers
                            </label>
                            <Field
                                id="numberOfTravelers"
                                name="numberOfTravelers"
                                component={FieldSelect}
                                stretch
                            >
                                <option value="" disabled>
                                    --
                                </option>
                                {Array(30)
                                    .fill()
                                    .map((_, i) => {
                                        return (
                                            <option key={i} value={`${i + 1}`}>
                                                {i + 1}
                                            </option>
                                        );
                                    })}
                            </Field>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label
                                htmlFor="tripLocationData"
                                className={'title'}
                            >
                                Where did you go?
                            </label>
                            <Field
                                name="tripLocationData"
                                component={PlacesAutocomplete}
                                placeholder="If multiple locations, list first city visited"
                                autoComplete={`${project.id}-tripLocation`}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label htmlFor="startDate" className={'title'}>
                                Start Date
                            </label>
                            <Field
                                name="startDate"
                                type="select"
                                component={DateInput}
                                showErrorText
                            />
                        </Col>
                        <Col>
                            <label className={'title'}>End Date</label>
                            <Field
                                name="endDate"
                                type="select"
                                component={DateInput}
                                showErrorText
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label htmlFor="thought" className={'title'}>
                                A Thought (optional)
                            </label>
                            <Field
                                stretch
                                id="thought"
                                name="thought"
                                type="textarea"
                                component={FieldInput}
                                maxLength="124"
                                showErrorText
                                placeholder="Describe the trip in a few sentences and/or list the places you went."
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label
                                htmlFor="weatherStatement"
                                className={'title'}
                            >
                                The weather was:
                            </label>
                            <Field
                                id="weatherStatement"
                                name="weatherStatement"
                                component={FieldSelect}
                                stretch
                            >
                                <option value="" disabled>
                                    --
                                </option>
                                {weatherOptions.map((option, i) => (
                                    <option key={i} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </Field>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label htmlFor="ateStatement" className={'title'}>
                                {pronoun} ate:
                            </label>
                            <Field
                                stretch
                                id="ateStatement"
                                name="ateStatement"
                                type="text"
                                component={FieldInput}
                                maxLength="25"
                                showErrorText
                                placeholder="Ex: “Hot dogs and smores”"
                                autoComplete={`${project.id}-ate`}
                            />
                        </Col>
                        <Col>
                            <label htmlFor="drankStatement" className={'title'}>
                                {pronoun} drank:
                            </label>
                            <Field
                                stretch
                                id="drankStatement"
                                name="drankStatement"
                                type="text"
                                component={FieldInput}
                                maxLength="25"
                                showErrorText
                                placeholder="Ex: “Beer and coffee”"
                                autoComplete={`${project.id}-drank`}
                            />
                        </Col>
                    </Row>
                    <Row>
                        {isVintageStyle && (
                            <Col>
                                <label
                                    htmlFor="woreStatement"
                                    className={'title'}
                                >
                                    {pronoun} wore:
                                </label>
                                <Field
                                    stretch
                                    id="woreStatement"
                                    name="woreStatement"
                                    type="text"
                                    component={FieldInput}
                                    maxLength="25"
                                    showErrorText
                                    placeholder="Ex: “Sunscreen” or “Lots of layers”"
                                    autoComplete={`${project.id}-wore`}
                                />
                            </Col>
                        )}
                        <Col>
                            <label
                                htmlFor="stayedStatement"
                                className={'title'}
                            >
                                {pronoun} stayed:
                            </label>
                            <Field
                                stretch
                                id="stayedStatement"
                                name="stayedStatement"
                                type="text"
                                component={FieldInput}
                                maxLength="25"
                                showErrorText
                                placeholder="Ex: “The Grand Hyatt” or “In the city”"
                                autoComplete={`${project.id}-stayed`}
                            />
                        </Col>
                    </Row>
                </Container>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

Details.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    previousPage: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
    metadata: PropTypes.object.isRequired,
};

Details.defaultProps = {
    invalid: false,
    submitting: false,
};

Details = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(Details);

export default Details;
