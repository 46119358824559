import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { loadImage } from 'stillnovel/utils/ImageLoader';

import styles from './ProductPreview.module.css';

// Converts a CSS string into an object
const cssInObject = styleString =>
    (styleString || '')
        .replace(/(?:\r\n|\r|\n|<|>)/g, '')
        .split(';')
        .filter(Boolean) // Remove empty entries
        .map(cur => cur.split(':'))
        .reduce((acc, [key, value]) => {
            if (key && value) {
                key = key.trim().replace(/-./g, css => css.toUpperCase()[1]);
                acc[key] = value.trim();
            }
            return acc;
        }, {});

function ProductPreview({
    active,
    children,
    frameImage: imageDefault,
    frameInlineStyles,
}) {
    const [image, setImage] = useState(imageDefault);

    useEffect(() => {
        const runEffect = async () => {
            if (active && imageDefault) {
                await loadImage(imageDefault);
            }
            setImage(imageDefault);
        };
        runEffect();
    }, [active, imageDefault]);

    if (!active || !image) {
        return <>{children}</>;
    }

    return (
        <div className={cx(styles.preview)}>
            <div
                style={{
                    backgroundImage: `url(${image})`,
                    backgroundSize: 'contain',
                }}
                className={styles.overlay}
            />
            <div
                className={styles.frame}
                style={cssInObject(frameInlineStyles)}
            >
                <div className={styles.art}>{children}</div>
            </div>
        </div>
    );
}

ProductPreview.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.node.isRequired,
    frameInlineStyles: PropTypes.string,
    frameImage: PropTypes.string,
};

ProductPreview.defaultProps = {
    active: false,
    frameInlineStyles: '',
    frameImage: '',
};

export default memo(ProductPreview);
