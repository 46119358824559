import PropTypes from 'prop-types';
import React, { Component } from 'react';
import moment from 'moment';
import ComboDatePicker from 'stillnovel/components/UI/Input/ComboDatePicker';
import Input from 'stillnovel/components/UI/Input';
import SvgIcon from 'stillnovel/components/UI/SvgIcon';

import styles from './Input.module.css';

const isValidDate = date => {
    return date instanceof Date && !isNaN(date);
};

class TimeComboSelect extends Component {
    render() {
        const {
            handleChangeHours,
            handleChangeMinutes,
            handleChangeMeridiem,
            hourValue,
            minuteValue,
            meridiemValue,
            error,
            touched,
        } = this.props;

        return (
            <div className={styles['time-select']}>
                <Input
                    id="hours"
                    name="hours"
                    value={hourValue}
                    type="select"
                    error={error}
                    touched={touched}
                    onChange={handleChangeHours}
                >
                    <option key={'disabled'} disabled value="">
                        HRS
                    </option>
                    {Array.from({ length: 12 }, (_, i) => {
                        const hour = i + 1; // 1 to 12
                        const padHour = String(hour).padStart(2, '0');
                        return (
                            <option key={hour} value={padHour}>
                                {hour}
                            </option>
                        );
                    })}
                </Input>
                <Input
                    id="minutes"
                    name="minutes"
                    value={minuteValue}
                    type="select"
                    error={error}
                    touched={touched}
                    onChange={handleChangeMinutes}
                >
                    <option key={'disabled'} disabled value="">
                        MIN
                    </option>
                    {Array.from({ length: 60 }, (_, i) => {
                        const padMinute = String(i).padStart(2, '0');
                        return (
                            <option key={i} value={padMinute}>
                                {padMinute}
                            </option>
                        );
                    })}
                </Input>
                <Input
                    id="meridiem"
                    name="meridiem"
                    value={meridiemValue}
                    type="select"
                    error={error}
                    touched={touched}
                    onChange={handleChangeMeridiem}
                >
                    <option key={'disabled'} disabled value="">
                        AM/PM
                    </option>
                    <option value="AM">AM</option>
                    <option value="PM">PM</option>
                </Input>
                <div className={styles['time-select--clock']}>
                    <SvgIcon iconType="clock" />
                </div>
            </div>
        );
    }
}

TimeComboSelect.propTypes = {
    error: PropTypes.any,
    handleChangeHours: PropTypes.func,
    handleChangeMinutes: PropTypes.func,
    handleChangeMeridiem: PropTypes.func,
    hourValue: PropTypes.string,
    minuteValue: PropTypes.string,
    meridiemValue: PropTypes.string,
    touched: PropTypes.any,
};

class DateInput extends Component {
    constructor(props) {
        super(props);

        const dateTime = moment(props.input.value);
        const isValid = dateTime.isValid();

        const hour24 = isValid ? dateTime.hour() : '';
        const hour12 = hour24 % 12 || 12;
        const meridiem = hour24 >= 12 ? 'PM' : 'AM';

        this.state = {
            date: isValid ? dateTime.toDate() : null,
            hours: isValid ? String(hour12).padStart(2, '0') : '',
            minutes: isValid ? dateTime.format('mm') : '',
            meridiem: isValid ? meridiem : '',
        };
    }

    componentDidUpdate(prevProps, prevState) {
        const { date, hours, minutes, meridiem } = this.state;
        const { input, showTime } = this.props;

        const dateChanged = prevState.date !== date && isValidDate(date);

        const timeChanged =
            showTime &&
            (prevState.hours !== hours ||
                prevState.minutes !== minutes ||
                prevState.meridiem !== meridiem);

        if ((dateChanged || timeChanged) && isValidDate(date)) {
            let hour = 0;
            let min = 0;

            if (showTime) {
                if (hours && minutes && meridiem) {
                    hour = parseInt(hours, 10);
                    min = parseInt(minutes, 10);

                    if (meridiem === 'PM' && hour < 12) {
                        hour += 12;
                    } else if (meridiem === 'AM' && hour === 12) {
                        hour = 0;
                    }
                } else {
                    // Time is incomplete, don't proceed
                    return;
                }
            }

            const dateTime = new Date(
                date.getFullYear(),
                date.getMonth(),
                date.getDate(),
                hour,
                min,
                0
            );
            if (isValidDate(dateTime)) {
                input.onChange(dateTime);
            }
        }
    }

    onChange = date => {
        this.setState({ date: isValidDate(date) ? date : null });
    };

    onChangeHours = e => {
        const { value } = e.target;
        this.setState({
            hours: value,
        });
    };

    onChangeMinutes = e => {
        const { value } = e.target;
        this.setState({
            minutes: value,
        });
    };

    onChangeMeridiem = e => {
        const { value } = e.target;
        this.setState({
            meridiem: value,
        });
    };

    render() {
        const {
            input,
            showTime,
            showErrorText,
            meta: { touched, error },
            ...props
        } = this.props;

        return (
            <div>
                <ComboDatePicker
                    {...input}
                    {...props}
                    error={error}
                    touched={touched}
                    order="mdy"
                    date={isValidDate(this.state.date) ? this.state.date : null}
                    yearOrder="descending"
                    placeholder="YYYY,MM,DD"
                    onChange={this.onChange}
                />
                {showTime && (
                    <TimeComboSelect
                        error={error}
                        touched={touched}
                        hourValue={this.state.hours}
                        minuteValue={this.state.minutes}
                        meridiemValue={this.state.meridiem}
                        handleChangeHours={this.onChangeHours}
                        handleChangeMinutes={this.onChangeMinutes}
                        handleChangeMeridiem={this.onChangeMeridiem}
                    />
                )}
                {showErrorText && touched && error && (
                    <p
                        className={'error-text body1'}
                        style={{
                            width: '100%',
                            padding: '1rem 0',
                        }}
                    >
                        {showErrorText && error}
                    </p>
                )}
            </div>
        );
    }
}

DateInput.propTypes = {
    input: PropTypes.object,
    meta: PropTypes.object,
    showErrorText: PropTypes.bool,
    showTime: PropTypes.bool,
};

export default DateInput;
