class ImageLoader {
    constructor() {
        this.active = true;
    }

    kill() {
        this.active = false;
    }

    load(img, { base64 = false, base64Type = 'image/jpeg' } = {}) {
        if (!img || typeof img !== 'string') {
            return Promise.reject(new Error('Invalid image source provided.'));
        }

        this.active = true;

        return new Promise((resolve, reject) => {
            const image = new Image();
            image.crossOrigin = 'anonymous';

            const addListeners = () => {
                image.addEventListener('load', handleSuccess);
                image.addEventListener('error', handleFailure);
            };

            const removeListeners = () => {
                image.removeEventListener('load', handleSuccess);
                image.removeEventListener('error', handleFailure);
            };

            const handleFailure = () => {
                removeListeners();
                reject(new Error(`Error loading image: ${img}`));
            };

            const handleSuccess = () => {
                removeListeners();

                if (!this.active) {
                    reject(new Error('Loading aborted.'));
                    return;
                }

                if (base64) {
                    try {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        if (!ctx) {
                            throw new Error(
                                'Canvas not supported in this environment.'
                            );
                        }

                        canvas.width = image.width;
                        canvas.height = image.height;
                        ctx.drawImage(image, 0, 0);

                        const dataURL = canvas.toDataURL(base64Type);
                        resolve(dataURL);
                    } catch (error) {
                        reject(
                            new Error(
                                `Failed to create base64 image: ${error.message}`
                            )
                        );
                    }
                } else {
                    resolve(image);
                }
            };

            addListeners();
            image.src = img;

            if (image.complete && image.naturalWidth && image.naturalHeight) {
                handleSuccess();
            }
        }).catch(error => {
            console.error('ImageLoader error:', error);
            throw error;
        });
    }
}

export const loadImage = (url, options = {}) => {
    if (!url || typeof url !== 'string') {
        return Promise.reject(new Error('Invalid URL for loadImage.'));
    }
    return new ImageLoader().load(url, options);
};

export default ImageLoader;
