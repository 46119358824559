import PropTypes from 'prop-types';
import React, { useState, useRef, forwardRef } from 'react';
import cx from 'classnames';
import { ReactCrop as Crop } from 'react-image-crop';
import Button from 'stillnovel/components/UI/Button';
import Figure from 'stillnovel/components/UI/Figure';
import isEqual from 'lodash/isEqual';

import 'react-image-crop/dist/ReactCrop.css';
import styles from './CropImage.module.css';

const CropImage = forwardRef(
    ({ value, targetWidth, targetHeight, onClose, src, onSave }, ref) => {
        const imgRef = useRef(null);
        const [cropState, setCrop] = useState([
            {
                ...value.crop,
            },
        ]);

        const isInitial = isEqual(
            cropState[0],
            cropState[cropState.length - 1]
        );

        const reset = e => {
            e.preventDefault();
            setCrop(prevCrop => [prevCrop[0]]);
        };

        const handleSave = e => {
            e.preventDefault();
            onSave(cropState[cropState.length - 1]);
            onClose(e);
        };

        const handleChangeCrop = (pixelCrop, crop) => {
            // keep initial crop in 0 index
            setCrop(prevCrop => [prevCrop[0], crop]);
        };

        const minWidth = imgRef.current?.width * (targetWidth / value.width);
        const minHeight =
            imgRef.current?.height * (targetHeight / value.height);

        return (
            <Figure
                ref={ref}
                className={cx(styles.root, !isInitial && styles['is-dirty'])}
            >
                <nav className={styles['crop-image-nav']}>
                    <Button
                        className={styles['crop-image-btn']}
                        block
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        className={styles['crop-image-btn']}
                        block
                        disabled={isInitial}
                        onClick={reset}
                    >
                        Undo
                    </Button>
                    <Button
                        className={styles['crop-image-btn']}
                        block
                        onClick={handleSave}
                    >
                        Save Crop
                    </Button>
                </nav>
                <div className={styles['crop-image-outer']}>
                    <Crop
                        aspect={targetWidth / targetHeight}
                        crop={cropState[cropState.length - 1]}
                        className={styles['crop-image']}
                        minWidth={minWidth}
                        minHeight={minHeight}
                        keepSelection
                        onChange={handleChangeCrop}
                    >
                        <img
                            ref={imgRef}
                            src={src}
                            crossOrigin="anonymous"
                            draggable={false}
                        />
                    </Crop>
                </div>
            </Figure>
        );
    }
);

CropImage.displayName = 'CropImage';

CropImage.propTypes = {
    crop: PropTypes.object,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    src: PropTypes.string,
    targetHeight: PropTypes.number,
    targetWidth: PropTypes.number,
    value: PropTypes.shape({
        crop: PropTypes.object,
        height: PropTypes.number,
        width: PropTypes.number,
    }),
};

export default CropImage;
