import React from 'react';
import PropTypes from 'prop-types';

import styles from './Page.module.css';

const Page = ({ children }) => {
    return <article className={styles.root}>{children}</article>;
};

Page.propTypes = {
    children: PropTypes.any.isRequired,
};

export default Page;
