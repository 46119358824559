import './styles/animations.css';
import './styles/colors.css';
import './styles/fonts.css';
import './styles/keyframes.css';
import './styles/layout.css';
import './styles/typography.css';
import './styles/zindex.css';
import './styles/root.css';

import React from 'react';
import { createRoot } from 'react-dom/client';
import Root from 'stillnovel/components/App/Root.js';
import { setupAuth } from 'stillnovel/utils/auth/policy';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { allowedSentryDomains } from 'stillnovel/utils';
import getConfig from 'core/config';

import store from './store';

Sentry.init({
    release: process.env.VERCEL_GIT_COMMIT_SHA || undefined,
    // Sentry is only enabled when DSN is defined. This disables Sentry in non-prod environments
    dsn:
        getConfig('debug') !== true
            ? 'https://dca349fb42164d8da76cceab0b00e381@o4504176056860672.ingest.sentry.io/4504176062627845'
            : null,
    environment: `${getConfig('sentryEnvironment')}`,
    allowUrls: allowedSentryDomains,
    integrations: [
        new BrowserTracing({
            tracePropagationTargets: [
                /^\//,
                'localhost',
                'api.dev.stillnovel.com',
                'api.stillnovel.com',
            ],
        }),
    ],
    tracesSampleRate: 1.0,
});

setupAuth(store, {});

const rootEl = document.getElementById('root');

const root = createRoot(rootEl);

function render(RootComponent) {
    root.render(<RootComponent store={store} />);
}

render(Root);
