import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import StepNav from 'stillnovel/components/App/Project/Builders/ArtPrint/StepNav';
import { Container, Col, Row } from 'stillnovel/components/UI/Grid';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import FieldSelect from 'stillnovel/components/UI/Input/FieldSelect';
import Form from 'stillnovel/components/UI/Form';
import FormHeader from 'stillnovel/components/App/Project/Builders/ArtPrint/FormHeader';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import { useSelector } from 'react-redux';

import validate from '../validate';

import Dog from './Dog';
import Cat from './Cat';
import Other from './Other';

const temperament = [
    'Clever',
    'Playful',
    'Stubborn',
    'Devoted ',
    'Lively',
    'Courageous',
    'Obedient',
    'Good-natured',
    'Devoted',
    'Self-assured',
    'Fearless',
    'Alert ',
    'Confident',
    'Steady',
    'Calm',
    'Intelligent',
    'Friendly',
    'Reliable',
    'Agile',
    'Mischievous',
    'Active',
    'Gentle',
];

let Details = ({
    handleSubmit,
    invalid,
    previousPage,
    project,
    submitting,
}) => {
    const pet = useSelector(state => {
        return state?.form?.project?.values?.pet;
    });
    const temperament1 = useSelector(state => {
        return state?.form?.project?.values?.temperament1;
    });
    const temperament2 = useSelector(state => {
        return state?.form?.project?.values?.temperament2;
    });
    return (
        <Fragment>
            <Form>
                <FormHeader heading="Details" />
                <Container>
                    <Row>
                        <Col>
                            <label htmlFor="thought" className={'form-title'}>
                                A Thought (optional)
                            </label>
                            <Field
                                stretch
                                id="thought"
                                name="thought"
                                type="textarea"
                                component={FieldInput}
                                maxLength="124"
                                showErrorText
                                placeholder="A sentence or two about what makes your pet special."
                            />
                        </Col>
                    </Row>
                    {pet === 'DOG' && <Dog />}
                    {pet === 'CAT' && <Cat />}
                    {pet === 'OTHER' && <Other />}

                    <Row alignment="bottom">
                        <Col colSpan={3}>
                            <label
                                htmlFor="temperament1"
                                className={'form-title'}
                            >
                                Temperament
                            </label>
                            <span>
                                <Field
                                    name="temperament1"
                                    component={FieldSelect}
                                >
                                    <option value="" disabled>
                                        --
                                    </option>
                                    {temperament.map(item => {
                                        if (temperament2 === item) {
                                            return null;
                                        }
                                        return (
                                            <option key={item} value={item}>
                                                {item}
                                            </option>
                                        );
                                    })}
                                </Field>{' '}
                                <span className={'body1-alt'}> and </span>{' '}
                                <Field
                                    name="temperament2"
                                    component={FieldSelect}
                                >
                                    <option value="" disabled>
                                        --
                                    </option>
                                    {temperament.map(item => {
                                        if (temperament1 === item) {
                                            return null;
                                        }
                                        return (
                                            <option key={item} value={item}>
                                                {item}
                                            </option>
                                        );
                                    })}
                                </Field>
                            </span>
                        </Col>
                    </Row>

                    <Row alignment="bottom">
                        <Col>
                            <label
                                htmlFor="favoriteToy"
                                className={'form-title'}
                            >
                                Favorite Toy
                            </label>
                            <Field
                                name="favoriteToy"
                                type="text"
                                component={FieldInput}
                                placeholder="Ex: “Tennis ball”"
                                autoComplete={`${project.id}-favoriteToy`}
                                autoCorrect="off"
                                maxLength={30}
                            />
                        </Col>
                    </Row>
                    <Row alignment="bottom">
                        <Col>
                            <label
                                htmlFor="favoriteTreat"
                                className={'form-title'}
                            >
                                Favorite Treat
                            </label>
                            <Field
                                name="favoriteTreat"
                                type="text"
                                component={FieldInput}
                                placeholder="Ex: “Peanut butter”"
                                autoComplete={`${project.id}-favoriteTreat`}
                                autoCorrect="off"
                                maxLength={30}
                            />
                        </Col>
                    </Row>
                </Container>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

Details.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    previousPage: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
};

Details.defaultProps = {
    invalid: false,
    submitting: false,
};

Details = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(Details);

export default Details;
