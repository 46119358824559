import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import StepNav from 'stillnovel/components/App/Project/Builders/ArtPrint/StepNav';
import { Container, Col, Row } from 'stillnovel/components/UI/Grid';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import Form from 'stillnovel/components/UI/Form';
import FormHeader from 'stillnovel/components/App/Project/Builders/ArtPrint/FormHeader';
import NavArrows from 'stillnovel/components/UI/NavArrows';
import TextPreview from 'stillnovel/components/UI/TextPreview';
import { stripEndPeriod } from 'stillnovel/utils';

import validate from '../validate';

let Details = ({
    handleSubmit,
    invalid = false,
    metadata,
    previousPage,
    project,
    submitting = false,
}) => {
    const isVintageStyle = project.metadata?.style === 'vintage';
    const charLimit = isVintageStyle ? 30 : 15;
    return (
        <Fragment>
            <Form>
                <FormHeader heading="Details" />
                <Container>
                    <Row>
                        <Col>
                            <label
                                htmlFor="favoriteFood"
                                className={'form-title'}
                            >
                                Favorite Food
                            </label>
                            <Field
                                name="favoriteFood"
                                type="text"
                                component={FieldInput}
                                placeholder="Ex: “Guacamole”"
                                autoComplete={`${project.id}-favoriteFood`}
                                autoCorrect="off"
                                maxLength={charLimit}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label
                                htmlFor="favoritePlace"
                                className={'form-title'}
                            >
                                Favorite Place
                            </label>
                            <Field
                                name="favoritePlace"
                                type="text"
                                component={FieldInput}
                                placeholder="Ex: “The front porch or Big Bear Lake”"
                                autoComplete={`${project.id}-favoritePlace`}
                                autoCorrect="off"
                                maxLength={charLimit}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label
                                htmlFor="favoritePastime"
                                className={'form-title'}
                            >
                                Favorite Pastime
                            </label>
                            <Field
                                name="favoritePastime"
                                type="text"
                                component={FieldInput}
                                placeholder="Ex: “Afternoon naps or hiking”"
                                autoComplete={`${project.id}-favoritePastime`}
                                autoCorrect="off"
                                maxLength={30}
                            />
                        </Col>
                    </Row>
                    <Row alignment="bottom">
                        <Col>
                            <label htmlFor="knownFor1" className={'form-title'}>
                                {`You are ${
                                    metadata?.isMemorial
                                        ? 'remembered'
                                        : 'known'
                                } for`}
                            </label>
                            <span>
                                <Field
                                    inline
                                    stretch
                                    name="knownFor1"
                                    component={FieldInput}
                                    placeholder="Ex: “Your crazy dance moves”"
                                    autoComplete={`${project.id}-knownFor1`}
                                    autoCapitalize="off"
                                    maxLength={45}
                                    style={{ minWidth: '258px' }}
                                />
                                <span className="form-title">{` and `}</span>
                                <Field
                                    inline
                                    stretch
                                    name="knownFor2"
                                    component={FieldInput}
                                    placeholder="Ex: “an infectious laugh”"
                                    autoComplete={`${project.id}-knownFor2`}
                                    autoCapitalize="off"
                                    maxLength={45}
                                    normalize={stripEndPeriod}
                                    style={{ minWidth: '258px' }}
                                />
                            </span>
                            <TextPreview
                                string={`You are ${metadata?.isMemorial ? 'remembered' : 'known'} for <em>${metadata.knownFor1 ?? ''}</em> and <em>${metadata.knownFor2 ?? ''}</em>.`}
                            />
                        </Col>
                    </Row>
                </Container>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

Details.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    metadata: PropTypes.shape({
        isMemorial: PropTypes.bool,
        knownFor1: PropTypes.string,
        knownFor2: PropTypes.string,
    }),
    previousPage: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    submitting: PropTypes.bool,
};

Details = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(Details);

export default Details;
