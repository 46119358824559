import React from 'react';
import PropTypes from 'prop-types';

import styles from './Form.module.css';

const FormRow = ({ ...props }) => (
    <section className={styles['form-row']} {...props} />
);

FormRow.propTypes = {
    children: PropTypes.node.isRequired,
};

export default FormRow;
