import React, { memo } from 'react';
import PropTypes from 'prop-types';

import styles from './StepNav.module.css';

const StepNav = ({ children }) => (
    <nav className={styles.root}>
        <div className={styles['cta-buttons']}>
            <div className={styles.buttons}>{children}</div>
        </div>
    </nav>
);

StepNav.propTypes = {
    children: PropTypes.node.isRequired,
};

export default memo(StepNav);
