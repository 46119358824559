import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import Button from 'stillnovel/components/UI/Button';
import SvgIcon from 'stillnovel/components/UI/SvgIcon';
import ArtPrintPreview from 'stillnovel/components/App/ArtPrintPreview';
import productsConfig from 'stillnovel/components/App/Project/config';

import { capitalize } from 'stillnovel/utils';

import icons from 'stillnovel/components/UI/SvgIcon/icons';

import getConfig from 'core/config';

import styles from './ProjectTile.module.css';

const PlaceholderLines = ({ lines = 1, height }) => (
    <div className={styles.placeholderLines}>
        {Array.from({ length: lines }).map((_, i) => (
            <div
                key={i}
                className={styles.placeholderLine}
                style={Object.assign(
                    i === lines - 1
                        ? { width: `${Math.floor(Math.random() * 25 + 50)}%` }
                        : { width: '100%' },
                    height ? { height: `${height}rem` } : {},
                    { animationDelay: `${i * 0.1}s` }
                )}
            />
        ))}
    </div>
);

PlaceholderLines.propTypes = {
    lines: PropTypes.number,
    height: PropTypes.number,
};

const ProjectTile = memo(({ project, deleteProject }) => {
    const dispatch = useDispatch();
    const [deleteState, setDeleteState] = useState('');

    const inCart = useSelector(state =>
        state.cart.lineItems.find(item => {
            return (
                item.customAttributes.find(attr => attr.key === '_projectId')
                    ?.value === project.id
            );
        })
    );

    useEffect(() => {
        if (deleteState === 'delete') {
            dispatch(deleteProject(project.id));
        }
    }, [deleteProject, deleteState, dispatch, project.id]);

    const isPlaceholder = !project?.id;

    let productConfig = productsConfig[project?.builderHandle];

    if (productConfig?.getNewConfig) {
        // Makes projects with old build handles backward compatible
        productConfig = productConfig.getNewConfig(project);
    }

    const placeholderPreview = (
        <Button
            className={styles.placeholderPreview}
            to={`${getConfig('marcomBaseUrl')}/products`}
        >
            <figure className={styles.icon}>
                <SvgIcon iconType="close" />
            </figure>
        </Button>
    );

    return (
        <li className={styles.tile}>
            {inCart && (
                <Button
                    to="/cart"
                    title="View in Cart"
                    className={styles['cart-icon']}
                >
                    <SvgIcon iconSrc={icons} iconType={'bag'} />
                </Button>
            )}
            <div className={styles['tile-inner']}>
                <div className={styles['tile-section']}>
                    {!isPlaceholder ? (
                        <ArtPrintPreview
                            {...project}
                            sku={project.shopifyProductId}
                            thumbnail
                            inSitu
                        />
                    ) : (
                        placeholderPreview
                    )}
                </div>
                <div className={styles['tile-section']}>
                    <div className={styles['tile-info']}>
                        {!isPlaceholder ? (
                            <span className="title">
                                {productConfig?.dashboardTitle?.(project)} -{' '}
                                {capitalize(project.metadata?.style)}
                            </span>
                        ) : (
                            <PlaceholderLines lines={3} height={2} />
                        )}
                        {!isPlaceholder && (
                            <>
                                <div className={styles.dateInfo}>
                                    <span
                                        className={`${styles['created-at']} body1`}
                                    >
                                        {`Created ${moment(
                                            project.createdAt
                                        ).format('MMM D, YYYY')}`}
                                    </span>
                                    <span
                                        className={`${styles['updated-at']} body1`}
                                    >
                                        {`Updated ${moment(
                                            project.updatedAt
                                        ).fromNow()}`}
                                    </span>
                                </div>
                                <div className={styles.buttons}>
                                    <Button
                                        small
                                        block
                                        outline
                                        to={`/project/${project.id}`}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        small
                                        block
                                        outline
                                        href={inCart ? `/cart` : null}
                                        onClick={() =>
                                            !inCart
                                                ? setDeleteState('confirm')
                                                : null
                                        }
                                    >
                                        {inCart ? 'In Cart' : 'Delete'}
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                    {(deleteState === 'confirm' ||
                        deleteState === 'delete') && (
                        <div className={styles.deletePrompt}>
                            <span className={`title ${styles.text}`}>
                                {`Are you sure you want to permanently delete ${productsConfig[
                                    project?.builderHandle
                                ]?.dashboardTitle?.(project)}?`}
                            </span>
                            <section>
                                <Button
                                    xsmall
                                    block
                                    onClick={() => setDeleteState('')}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    xsmall
                                    outline
                                    disabled={deleteState === 'delete'}
                                    onClick={() => setDeleteState('delete')}
                                >
                                    {deleteState === 'delete'
                                        ? 'Deleting...'
                                        : 'Yes, Delete'}
                                </Button>
                            </section>
                            <span className="quote">
                                This cannot be undone.
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </li>
    );
});

ProjectTile.displayName = 'ProjectTile';

ProjectTile.propTypes = {
    deleteProject: PropTypes.func,
    project: PropTypes.shape({
        builderHandle: PropTypes.string,
        createdAt: PropTypes.string,
        id: PropTypes.any,
        metadata: PropTypes.object,
        shopifyProductId: PropTypes.string,
        updatedAt: PropTypes.string,
    }),
};

export default ProjectTile;
