import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Button from 'stillnovel/components/UI/Button';
import Loader from 'stillnovel/components/UI/Loader';
import Section from 'stillnovel/components/UI/Section';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { useSelector, useDispatch } from 'react-redux';
import { clearCheckout } from 'stillnovel/store/cart/actions';

import getConfig from 'core/config';

import Header from 'stillnovel/components/App/Header';

import Summary from '../Summary';

import CartItems from './CartItems';
import styles from './Cart.module.css';

function Cart() {
    const [isCheckingOut, setCheckingOut] = useState(false);
    const location = useLocation();
    const query = qs.parse(location.search.replace('?', ''));
    const dispatch = useDispatch(state => state.cart);

    const cart = useSelector(state => state.cart);
    const canRenderCart = useSelector(
        state =>
            state.cart.loadState === 'CART/LOADED' ||
            state.cart.loadState === 'CART/LOADING'
    );

    const isStale = useSelector(state => state.cart.isStale);

    useEffect(() => {
        if (query?.action === 'complete') {
            dispatch(clearCheckout());
        }
    }, [query?.action, dispatch]);

    function renderCartEmpty() {
        return (
            <Section className={styles['empty-copy']}>
                <h3 className="title">Your cart is empty.</h3>
                <p className="body1">
                    {`Start adding items to
                    your cart by browsing our product line.`}
                </p>
                <Button
                    small
                    block
                    outline
                    to={`${getConfig('marcomBaseUrl')}/products`}
                    className={styles.button}
                >
                    Explore products
                </Button>
            </Section>
        );
    }

    function renderCart() {
        return (
            <React.Fragment>
                <CartItems
                    cartItems={cart.lineItems}
                    subTotal={cart.subtotalPrice}
                />
                <Summary
                    showTotal={false}
                    showDiscount={false}
                    showGiftCard={false}
                    showShipping={false}
                    copy
                    cta={() => (
                        <Button
                            block
                            large
                            disabled={isStale || !canRenderCart}
                            href={cart.webUrl?.replace(
                                /stillnovel\.myshopify\.com/gi,
                                'shop.stillnovel.com'
                            )}
                            onClick={() => {
                                setCheckingOut(true);
                            }}
                        >
                            Checkout
                        </Button>
                    )}
                />
            </React.Fragment>
        );
    }

    const cartEmpty = cart?.lineItems?.length === 0;
    const isLoading = !canRenderCart;

    return (
        <div className={styles.cart}>
            <Helmet>
                <title>Cart</title>
            </Helmet>
            <Header headline="Cart" />
            {isLoading || isCheckingOut ? (
                <Loader fullscreen />
            ) : cartEmpty ? (
                renderCartEmpty()
            ) : (
                renderCart()
            )}
        </div>
    );
}

Cart.autoload = false;

export default Cart;
