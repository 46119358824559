import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Link from 'stillnovel/components/UI/Link';
import SvgIcon from 'stillnovel/components/UI/SvgIcon';

import styles from './CloseButton.module.css';

const CloseButton = ({ className, children, to, ...props }) => {
    const classNames = cx(styles.btn, className);
    if (to) {
        return (
            <Link {...props} className={classNames} to={to}>
                <SvgIcon iconType="close" />
                {children && (
                    <span className={`body1-alt ${styles.label}`}>
                        {children}
                    </span>
                )}
            </Link>
        );
    }
    return (
        <button {...props} className={classNames}>
            <SvgIcon iconType="close" />
            {children && (
                <span className={`body1-alt ${styles.label}`}>{children}</span>
            )}
        </button>
    );
};

CloseButton.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    to: PropTypes.string,
};

export default CloseButton;
