import React from 'react';
import { Helmet } from 'react-helmet';
import AuthTrigger from 'stillnovel/components/App/Auth/Trigger';
import Button from 'stillnovel/components/UI/Button';
import { useSelector } from 'react-redux';

import styles from './NotFound.module.css';

export default function NotFound() {
    const userRole = useSelector(state => state.auth?.user?.role);

    return (
        <div className={styles.root}>
            <Helmet>
                <title>{`Project missing or you don't have access`}</title>
            </Helmet>
            <div className={styles.inner}>
                <h1 className="jumbo">Oops!</h1>
                <h3 className="title">
                    {`Project missing or you don't have access.`}
                </h3>
                <section className={styles.contentProject}>
                    {userRole !== 'guest' && (
                        <>
                            <p className="body1-alt">
                                {`Please confirm that you have the correct link.
                                If you do, it's possible that you created
                                this project while signed in to another account
                                or the project doesn't exist.`}
                            </p>
                            <Button className={styles.cta} block to="/">
                                Dashboard
                            </Button>
                        </>
                    )}
                    {userRole === 'guest' && (
                        <>
                            <p className="body1-alt">
                                {`Please confirm that you have the correct link.
                                If you do, it's possible that you created
                                this project while signed in or on a different
                                device.`}
                            </p>
                            <AuthTrigger>
                                <Button className={styles.cta} block>
                                    Login
                                </Button>
                            </AuthTrigger>
                        </>
                    )}
                </section>
            </div>
        </div>
    );
}
