import React from 'react';
import PropTypes from 'prop-types';

import styles from './FormHeader.module.css';

const FormHeader = ({ heading }) => (
    <div className={styles.root}>
        <h1 className={'form-header'}>{heading}</h1>
    </div>
);

FormHeader.propTypes = {
    heading: PropTypes.string.isRequired,
};

export default FormHeader;
