import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSWR from 'swr';
import { useMount } from 'react-use';
import { Helmet } from 'react-helmet';
import { states } from 'stillnovel/store/auth/actions';
import Button from 'stillnovel/components/UI/Button';
import Loader from 'stillnovel/components/UI/Loader';
import AuthTrigger from 'stillnovel/components/App/Auth/Trigger';
import SN from 'stillnovel/services';
import { deleteProject, getProjects } from 'stillnovel/store/projects/actions';
import ProjectTile from 'stillnovel/components/App/Account/Dashboard/ProjectTile';
import Header from 'stillnovel/components/App/Header';
import getConfig from 'core/config';

import styles from './Dashboard.module.css';

const Dashboard = () => {
    const projects = useSelector(state => state.projects.items);
    const isLoading = useSelector(state => state.projects.isLoading);
    const user = useSelector(state => state.auth.user);
    const authState = useSelector(state => state.auth.state);

    const guestUser = user?.role === 'guest';
    const dispatch = useDispatch();

    // Fetch all products from Shopify
    const { data: shopifyProducts } = useSWR('products', () =>
        SN.shopifyClient.product.fetchAll()
    );

    const productsKeyed =
        shopifyProducts?.reduce((acc, product) => {
            acc[product.handle] = product;
            product.variants.forEach(variant => {
                acc[variant.id] = variant;
                if (variant.handle) acc[variant.handle] = variant;
            });
            return acc;
        }, {}) || {};

    useMount(() => {
        dispatch(getProjects());
    });

    const renderProjects = () => {
        const sortedProjects = [...projects].sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
        );

        if (sortedProjects.length === 0) {
            return (
                <>
                    <h4>
                        <Button
                            className="title"
                            to={`${getConfig('marcomBaseUrl')}/products`}
                        >
                            Create your first project
                        </Button>
                    </h4>
                    <ul
                        style={{ opacity: 0.4 }}
                        role="list"
                        className={styles.list}
                    >
                        <ProjectTile project={{}} />
                    </ul>
                </>
            );
        }

        return (
            <ul role="list" className={styles.list}>
                {sortedProjects.map(project => (
                    <ProjectTile
                        key={project.id}
                        project={project}
                        productsKeyed={productsKeyed}
                        deleteProject={deleteProject}
                    />
                ))}
            </ul>
        );
    };

    const renderLoading = () => (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <Loader align="left" theme="small" text="Fetching projects..." />
        </div>
    );

    const renderContent = () => {
        if (isLoading || authState !== states.LOGGED_IN) {
            return renderLoading();
        }

        return (
            <div className={styles.content}>
                <Header headline="My Projects" />
                {guestUser && (
                    <section className={styles.guestUserContent}>
                        <h3 className="title">Log in to view your projects</h3>
                        <p className="body1">
                            You can create, view, or edit projects across all
                            devices once you’ve logged in.
                        </p>
                        <AuthTrigger>
                            <Button block>Log in</Button>
                        </AuthTrigger>
                    </section>
                )}
                <div className={styles.list}>
                    {projects.length > 0 && (
                        <h4 className="title">
                            {guestUser
                                ? 'Recent Drafts'
                                : 'Recently Saved Projects'}
                        </h4>
                    )}
                    {renderProjects()}
                </div>
            </div>
        );
    };

    return (
        <>
            <Helmet>
                <title>Projects</title>
            </Helmet>
            {renderContent()}
        </>
    );
};

export default Dashboard;
