import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Field from 'redux-form/es/Field';
import reduxForm from 'redux-form/es/reduxForm';
import { Container, Col, Row } from 'stillnovel/components/UI/Grid';
import FieldInput from 'stillnovel/components/UI/Input/FieldInput';
import FieldSelect from 'stillnovel/components/UI/Input/FieldSelect';
import Form from 'stillnovel/components/UI/Form';
import NavArrows from 'stillnovel/components/UI/NavArrows';

import FormHeader from '../../../FormHeader';
import validate from '../validate';
import StepNav from '../../../StepNav';

const dates = new Array(100).fill('');

let Stats = ({ handleSubmit, invalid, previousPage, submitting, project }) => {
    return (
        <Fragment>
            <Form>
                <FormHeader heading="Additional Details" />
                <Container>
                    <Row>
                        <Col>
                            {/* Vintage only */}
                            <span className={'form-title'}>
                                We met{' '}
                                <span>
                                    <Field
                                        inline
                                        id="metLocationPreposition"
                                        name="metLocationPreposition"
                                        component={FieldSelect}
                                        stretch
                                    >
                                        <option value="" disabled>
                                            --
                                        </option>
                                        <option value="in">in</option>
                                        <option value="at">at</option>
                                    </Field>{' '}
                                    <Field
                                        inline
                                        stretch
                                        name="metLocationName"
                                        component={FieldInput}
                                        placeholder="Ex: “the bowling alley”"
                                        autoComplete={`${project.id}-metLocationName`}
                                        autoCapitalize="off"
                                        maxLength={45}
                                        style={{ minWidth: '190px' }}
                                    />{' '}
                                    in{' '}
                                    <Field
                                        inline
                                        id="metYear"
                                        name="metYear"
                                        component={FieldSelect}
                                        stretch
                                    >
                                        <option value="" disabled>
                                            --
                                        </option>
                                        {dates.map((o, i) => {
                                            const date =
                                                parseInt(
                                                    new Date().getFullYear()
                                                ) - i;
                                            return (
                                                <option key={date} value={date}>
                                                    {date}
                                                </option>
                                            );
                                        })}
                                    </Field>
                                </span>
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label htmlFor="thought" className={'form-title'}>
                                A Thought (optional)
                            </label>
                            <Field
                                stretch
                                id="thought"
                                name="thought"
                                type="textarea"
                                component={FieldInput}
                                maxLength="124"
                                showErrorText
                                placeholder="A sentence or two about what makes you special or unique as a couple."
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <label htmlFor="songTitle" className={'form-title'}>
                                Our Song
                            </label>
                            <Field
                                name="songTitle"
                                component={FieldInput}
                                placeholder="Ex: “Only love”"
                                autoComplete={`${project.id}-songTitle`}
                                autoCorrect="off"
                            />
                        </Col>

                        <Col>
                            <label
                                htmlFor="songArtist"
                                className={'form-title'}
                            >
                                Song Artist
                            </label>
                            <Field
                                name="songArtist"
                                component={FieldInput}
                                placeholder="Ex: “Ben Howard”"
                                autoComplete={`${project.id}-songArtist`}
                                autoCorrect="off"
                            />
                        </Col>
                    </Row>
                    {project.metadata?.style === 'modern' && (
                        <Row>
                            <Col>
                                <label
                                    htmlFor="songLyric"
                                    className={'form-title'}
                                >
                                    Song Lyric
                                </label>
                                <Field
                                    name="songLyric"
                                    component={FieldInput}
                                    placeholder="Ex: “Darling you’re with me,
                            always around me.”"
                                    autoComplete={`${project.id}-songLyric`}
                                    maxLength="84"
                                    autoCorrect="off"
                                />
                            </Col>
                        </Row>
                    )}
                </Container>
            </Form>
            <StepNav>
                <NavArrows
                    buttons={[
                        { onClick: previousPage },
                        {
                            onClick: handleSubmit,
                            disabled: invalid || submitting,
                            label: 'Review',
                        },
                    ]}
                />
            </StepNav>
        </Fragment>
    );
};

Stats.propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    invalid: PropTypes.bool,
    previousPage: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    project: PropTypes.object.isRequired,
};

Stats.defaultProps = {
    invalid: false,
    submitting: false,
};

Stats = reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: false,
    touchOnBlur: false,
    touchOnChange: true,
    validate,
})(Stats);

export default Stats;
