import { allowedCharacters } from 'stillnovel/constants';

export const allowedSentryDomains = [
    /https?:\/\/((my.dev|my)\.)?stillnovel\.com/,
    /https?:\/\/((.*)\.)?vercel\.app/,
];

export function getDisplayName(component) {
    if (!component) return 'Component';
    return (
        component.displayName ||
        component.name ||
        (component.type && component.type.displayName) ||
        'Component'
    );
}

export function zeroFill(number, width) {
    const numStr = number.toString();
    const padding = width - numStr.length;
    if (padding > 0) {
        return '0'.repeat(padding) + numStr;
    }
    return numStr;
}

export function getCurrencySymbol(type = 'default') {
    const symbols = {
        usd: '$',
        default: '',
    };
    return symbols[type.toLowerCase()] || symbols.default;
}

export function getSubTotal(cartItems = []) {
    if (!Array.isArray(cartItems) || cartItems.length === 0) {
        return '0.00';
    }
    return cartItems
        .reduce(
            (total, item) =>
                total + (item.project?.product?.price || 0) * item.qty,
            0
        )
        .toFixed(2);
}

export function emailMask(email = '') {
    if (typeof email !== 'string' || !email.includes('@')) {
        console.warn(`Invalid email provided: ${email}`);
        return email;
    }
    const [localPart, domain] = email.split('@');
    const visiblePartLength = Math.ceil(localPart.length / 2);
    const maskedPart = '*'.repeat(localPart.length - visiblePartLength);
    return localPart.slice(0, visiblePartLength) + maskedPart + '@' + domain;
}

export function isTouch() {
    return (
        typeof window !== 'undefined' &&
        ('ontouchstart' in window || navigator.maxTouchPoints > 0)
    );
}

export function getNaturalImageDimensions(path = '') {
    if (typeof path !== 'string' || !path) {
        return Promise.reject(new Error('Invalid image path provided.'));
    }
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = () =>
            resolve({
                width: img.naturalWidth,
                height: img.naturalHeight,
            });
        img.onerror = () =>
            reject(
                new Error('Error loading image in `getNaturalImageDimensions`.')
            );
        img.src = path;
    });
}

export function getFileExtensionFromPath(path = '') {
    if (typeof path !== 'string') return '';
    return path.slice(((path.lastIndexOf('.') - 1) >>> 0) + 2);
}

export function toUSDFloat(int) {
    return typeof int === 'number' ? int / 100 : 0;
}

export function stripQuotes(value = '') {
    return typeof value === 'string' ? value.replace(/["]+/g, '') : value;
}

export function stripWhitespace(value = '') {
    return typeof value === 'string' ? value.trim() : value;
}

export function stripEndPeriod(value = '') {
    return typeof value === 'string' ? value.replace(/[.$]+/g, '') : value;
}

export function sanitizeString(value = '') {
    if (typeof value !== 'string') {
        console.warn(
            `Parameter warning: sanitizeString() expects a string. Current parameter type: ${typeof value}. Skipping sanitation.`
        );
        return value;
    }
    return [...value].filter(char => allowedCharacters.test(char)).join('');
}

export function capitalize(value = '') {
    if (typeof value !== 'string') {
        console.warn(
            `Parameter warning: capitalize() expects a string. Current parameter type: ${typeof value}. Skipping capitalization.`
        );
        return value;
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
}

export function scrollToTop() {
    if (typeof window !== 'undefined') {
        window.requestAnimationFrame(() => window.scrollTo(0, 0));
    }
}
